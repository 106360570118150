@import './styles/typography.scss';
@import './styles/colors.scss';
@import './styles/reset.scss';
@import './styles/responsive-select.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  background: $background;
  //height: 100%;
}
html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.current {
  border-bottom: 4px solid white;
}
.ReactVirtualized__Grid {
  outline: none!important;
}
.download-icon--small {
  font-size: 0.8rem;
  
}
.people-icon--medium {
  font-size: 1.5rem;
}



