@import "../styles/typography.scss";
@import "../styles/colors.scss";
@import "../styles/reset.scss";
@import "../styles/mixins.scss";
.nav {
  $nav-height: 2.5rem; // variable size
  font-family: $font-family;
  background: transparent;
  color: #fff;
  top: 0; 
  width: 100%;
  padding: 0 0.3rem;
  position: fixed;
  z-index: 12;

  //transition: all 180ms ease-out;
  &--white {
    background: white;
    box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.1);
  }

  &__transparent {
    height: $nav-height;
    background: #000000;    
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.042), 0px 3px 14px 2px rgba(0, 0, 0, 0.036);

    .nav__user-section {
      right: 0.5rem;
      top: 0.25rem;
      color: #fff;
    }
    .nav__logo {
      width: 2.5rem;
      margin-top: 0.5rem;
      margin-left: 0;
      cursor: pointer;
    }
  }
  &__logo {
    position: relative;
    width: 2.5rem;
    margin-top: 0.5rem;
    margin-left: 0;
  }

  &__login-area {
    top: 0rem;
    right: 1.5rem;
    position: absolute;
    line-height: 2.2rem;
  }

  &__signin-area {
    top: 0.5rem;
    right: 1rem;
    position: absolute;
    line-height: 2.2rem;
  }  
  
  &__header {
    margin: 0;
    color: $primary;
    display: inline-block;
    line-height: 2.4rem;
    font-size: $font-size-small;
    font-weight: 600;
  }

  &__list {
    background: white;
    color: $dark;
    float: right;
    @include clearfix-micro();
  }

  &__list-item {
    color: $white;
    display: inline-block;
    margin-left: 0.5rem;

    a {
      font-size: $font-size-tiny;
      text-transform: uppercase;
      line-height: $nav-height;
    }
  }

  &__notification {
    position: absolute;
    top: 0.5rem;
    left: 0.6rem;
    background: #fff;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    z-index: 1;
    span {
      font-size: 8px;
      color: white;
      position: relative;
      top: -21px;
      left: 0.2rem;
    }
  }
  &__user-section {
    top: 0;
    right: 2rem;
    position: absolute;
    line-height: 2rem;
  }
  &__simple-button {
    
    font-size: 0.5rem;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    left: 1.4rem;
    margin-right: 1.5rem;
    &:hover {
      cursor: pointer;
      color: #0273b5;
    }
    svg {
      position: relative;
      top: 0.25rem;
      width: 1rem;
      height: 1rem;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0.6rem;
      right: -1rem;
      height: 1rem;
      width: 1px;
      background: #fff;
    }    
  }
  &__simple-button_login_sigup {  
    margin-top: 0.1rem;  
    font-size: 0.5rem;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    left: 1.4rem;
    &:hover {
      cursor: pointer;
      color: #0273b5;
    }  
  }
  &__inbox {
    display: inline-block;
    position: relative;
    margin-right: 0.6rem;
    &:hover {
      cursor: pointer;
      color: #0273b5;
    }
    svg {
      position: relative;
      top: 0.1rem;
      width: 1rem;
      height: 1rem;
      margin-right: 0.2rem;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0.6rem;
      right: -1rem;
      height: 1rem;
      width: 1px;
      background: #fff;
    }
  }
  &__studies {
    display: inline-block;
    position: relative;
    margin-right: 0.6rem;
    &:hover {
      cursor: pointer;
      color: #0273b5;
    }
    svg {
      position: relative;
      top: 0.1rem;
      width: 1rem;
      height: 1rem;
      margin-right: 0.2rem;
    }
    /*&:after {
      content: "";
      position: absolute;
      top: 0.6rem;
      right: -1rem;
      height: 1rem;
      width: 1px;
      background: #fff;
    }*/
  }  
  &__user-title {
    font-size: $font-size-tiny;
  }
  &__user-section-label {
    margin-left: 0.3rem;
    font-size: 0.5rem;
    text-transform: uppercase;
  }
  &__user-section-lock-icon {
    min-width: 0.8rem;
    min-height: 0.8rem;
  }
}
